<template>
    <header class="header">
        <div class="header-content">
            <div v-if="userIsSuperAdmin" class="header-item d-flex align-items-center">
                <div class="site-logo-icon">
                    <router-link to="/clients">
                        <SiteLogoIcon/>
                    </router-link>
                </div>
                <h4 class="dashboard-title">Admin Console</h4>
            </div>
            <div v-else class="header-item d-flex align-items-center">
                <div class="site-logo-icon">
                    <router-link to="/messages">
                        <SiteLogoIcon/>
                    </router-link>
                </div>
                <h4 class="dashboard-title">Operator Console <span class="beta-mode">/ Beta</span></h4>
            </div>
            <div class="header-item menu-item" :class="{'show': isMenuOpen}">
                <!-- user?.AccountHolder || user?.SaasOperator -->
                <nav v-if="userIsSuperAdmin" class="navbar justify-content-center">
                    <router-link 
                        to="/clients" 
                        class="nav-link d-flex align-items-center" 
                        :class="{ 'active': $route.path === '/clients' || $route.path.startsWith('/clients/') }">
                        Clients
                    </router-link>
                    <router-link 
                        to="/archive"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/archive' || $route.path.startsWith('/archive/') }">
                        Archive
                    </router-link>
                </nav>

                <nav v-if="user?.SaasOperator" class="navbar justify-content-center">
                    <router-link 
                        to="/contacts" 
                        class="nav-link d-flex align-items-center" 
                        :class="{ 'active': $route.path === '/contacts' || $route.path.startsWith('/contacts/') }">
                        Contacts
                    </router-link>
                    <router-link 
                        to="/group"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/group' || $route.path.startsWith('/group/') }">
                        Groups
                    </router-link>
                    <router-link 
                        to="/messages"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/messages' || $route.path.startsWith('/messages/') }">
                        Messages
                    </router-link>
                    <router-link 
                        to="/activity"
                        class="nav-link d-flex align-items-center"
                        :class="{ 'active': $route.path === '/activity' || $route.path.startsWith('/activity/') }">
                        Activity
                    </router-link>
                </nav>
                
                <div class="logout-links-con d-flex d-md-none align-items-center justify-content-center">
                    <h4 class="username"  @click="TogglePopup('buttonTrigger')">{{user?.FirstName}} {{ user?.LastName }}</h4>
                    <div class="logout-icon" @click="logout();">
                        <LogOutIcon/>
                    </div>
                </div>
            </div>
            <div class="header-item d-none d-md-flex align-items-center justify-content-end">
                <v-menu>
                <template v-slot:activator="{ props }">
                    <button 
                    v-bind="props"
                    class="btn-user"
                    >
                    <h4>{{user?.FirstName}} {{ user?.LastName }}</h4>
                    <NavmenuPulldownButton/>
                    </button>
                </template>
                <v-list>
                    <v-list-item @click="TogglePopup('buttonTrigger')">
                        <v-list-item-title><NavmenuProfileIcon/> Profile</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="navigateTo('/admin')" v-if="user?.AccountHolder || user?.SaasAdmin">
                        <v-list-item-title><NavmenuAdminIcon/> Admin</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="navigateTo('/account')" v-if="user?.AccountHolder">
                        <v-list-item-title><NavmenuAccountIcon/> Usage</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout();">
                        <v-list-item-title><NavmenuLogout/> Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
            </div>
            
            <button class="btn btn-toggle d-block d-md-none" @click="toggleMenu">
                <MenuIcon/>
            </button>
        </div>
        <!-- <UpdateProfile 
            v-if="popupTriggers.buttonTrigger"  :title="'Operator'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>ProfileSaved()" >
        </UpdateProfile> -->
        <!-- <AddUser v-if="popupTriggers.buttonTrigger" :editUser="user" :title="'Update Profile'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :source="'profile'" /> -->
        <UpdateProfile 
            v-if="popupTriggers.buttonTrigger"  :title="'Operator'"
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) =>ProfileSaved()" >
        </UpdateProfile>
    </header>
</template>
<script>
import SiteLogoIcon from '@/components/icons/SiteLogoIcon.vue';
import LogOutIcon from '@/components/icons/LogOutIcon.vue';
import MenuIcon from '@/components/icons/MenuIcon.vue';
import UpdateProfile from '../UpdateProfile.vue'; 
import AddUser from '../AddUser.vue';
import { computed, ref } from 'vue';
import { VMenu, VList, VListItem, VListItemTitle} from 'vuetify';
import NavmenuAccountIcon from '@/components/icons/NavmenuAccountIcon.vue';    
import NavmenuAdminIcon from '@/components/icons/NavmenuAdminIcon.vue';
import NavmenuLogout from '@/components/icons/NavmenuLogout.vue';
import NavmenuProfileIcon from '@/components/icons/NavmenuProfileIcon.vue';
import NavmenuPulldownButton from '@/components/icons/NavmenuPulldownButton.vue';
import { useRouter } from 'vue-router';
import { globalState } from '@/globals.js';
import AccountDeactivatedModal from '../modals/AccountDeactivatedModal.vue';


export default {
    name: 'Header',
    components: { UpdateProfile, SiteLogoIcon, LogOutIcon, MenuIcon, VMenu, VList, VListItem, VListItemTitle,NavmenuAccountIcon,NavmenuAdminIcon,NavmenuLogout,NavmenuProfileIcon,NavmenuPulldownButton },
    setup () {
        const popupTriggers = ref({
            buttonTrigger: false
        });		
        const TogglePopup = (trigger) => {
            popupTriggers.value[trigger] = !popupTriggers.value[trigger]
        }
        const router = useRouter();
        return {
            UpdateProfile,
            popupTriggers,
            TogglePopup,
            router,
        }
    },
    mounted(){
        if(this.isFirstTime && !this.user.Phone) {
            this.TogglePopup("buttonTrigger");
        }
    }, 
    data() {
        return {
            isMenuOpen: false,
            user: computed(()=>this.$store.getters.user),
            isFirstTime: computed(()=>this.$store.getters.isFirstTime)
        };
    },
    computed: {
        userIsSuperAdmin() {
            return globalState.isSuperAdmin;
        },
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        isActive(path) {
            return ref(this.$router.currentRoute).value.path === path
        },
        logout(){
            this.$root.logout();
        },
        ProfileSaved(){
            this.popupTriggers.buttonTrigger = false;
            window.location.reload();
        },
        navigateTo(path) {
            this.router.push(path);
        }
    }
}
</script>
<style lang="scss" scoped>
 .header{
     width: 100%;
     padding: 15px 25px 15px 15px;
     background: #F2F2F2;
     position: relative;
     min-height: 80px;
     .header-content{
        display: grid;
        grid-template-columns: 7fr 10fr 7fr;
        align-items: center;
    }
    .site-logo-icon{
        margin-right: 25px;
        width: 50px;
        height: 50px;
    }
    .header-item{
        min-width: 215px;
        h4{
            color: #000;
            font-family: "Roboto";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 80%;
            & .beta-mode {
                font-size: 15px;
                line-height: 16px;
                font-weight: 400;
                color: #7B8794;
            }
            &.username{
                padding: 7px 6px;
                font-weight: 400;
                cursor: pointer;
                &:hover{
                    background: #fff;
                    border-radius: 8px;
                }
            }
        }
    }
    .menu-item{
        @media screen and (max-width: 960px) {
            display: none;
            position: absolute;
            z-index: 9;
            width: 100%;
            top: 100%;
            left: 0;
            background: #ffffff;
            padding: 30px;
            box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
            &.show{
                display: block;
            }
            .navbar{
                flex-direction: column;
            }
            .logout-links-con{
                margin-top: 30px;
            }

        }
    }
    .logout-icon{
        margin-left: 8px;
    }
    .btn-toggle{
        outline: none;
        opacity: 0.7;
        &:active, &:focus{
            outline: none;
            box-shadow: none;
        }
    }
    .navbar{
        padding: 0;
        display: flex;
        align-items: center;
        gap: 36px;
        .nav-link{
            padding: 8px 0 4px;
            display: inline-flex;
            align-items: flex-start;
            color: #000;
            text-align: center;
            font-family: "Roboto";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            border-bottom: 4px solid transparent;
            &.active, &:hover{
                border-bottom-color: #000;
            }
        }
        @media screen and (max-width: 768px) {
            gap: 30px;
        }
    }
    .btn-user{
        background: transparent;
        border-radius: 5px;
        padding: 3px 3px 3px 7px;
        cursor: pointer;
        box-shadow: none;
        text-transform: none;
        display: flex;
        align-items: center;
        // min-width: 162px;
        gap: 1px;
        transition: all 0.2s ease;
        justify-content: flex-end;
        & h4 {
            font-size: 20px;
            line-height: 16px;
            font-weight: 400;
            color: #000;
        }
        &:hover , &:focus{
            background: #fff;
        }
    }
 }
 .v-list-item {
    padding: 12px 20px;
 }
 .v-list-item-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    color:#828282;
 }
 
 .v-list.v-theme--light.v-list--density-default.v-list--one-line {
    max-width: 162px !important;
    min-width: 162px !important;
    margin-left: auto;
    width: 162px !important;
    margin-top: 4px;
 }
</style>