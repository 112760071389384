<template>
  <div class="group_member_dialog">
    <v-dialog
      v-model="dialog"
      max-width="1000"
      width="1000"
      height="800"
      persistent
    >
      <v-card class="group-member__dialog" height="800">
        <button type="button"
              class="close-btn-icon"
              @click="closeDialog"
            >
            <span class="outline">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
            <span class="outline_hover">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
                <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
                </svg>
            </span>
        </button>
        <div class="group-member__list">
          <v-tabs
            class="custom-btn"
            v-model="tab"
            align-tabs="center"
            color="deep-purple-accent-4"
          >
            <v-tab
              class="px-0"
              :disabled="disableTabs.members"
              :value="1"
              @click="loadMember"
              >Members</v-tab
            >
            <v-tab
              class="px-0"
              :disabled="isTab || disableTabs.contacts"
              :value="2"
              @click="loadContact"
              >Contacts</v-tab
            >
            <v-tab
              class="px-0"
              :disabled="isTab || disableTabs.group"
              :value="3"
              @click="loadDetail"
              >Details</v-tab
            >
          </v-tabs>

          <v-window v-model="tab">
            <v-window-item :value="1">
              <v-container fluid>
                <MemberTab
                  :group="group"
                  ref="membertab"
                  :defaultMember="defaultGroupMembers"
                  :isGroupEdit="isGroupEdit"
                  :selectedContactItems="selectedContacts"
                  @update-member="groupMembers"
                  @closeDialog="closeDialog"
                  @removeMember="toggleTabs"
                  @removeMemberDone="toggleTabsEnable"
                  @enableSave="enableSave"
                />
              </v-container>
            </v-window-item>
            <v-window-item :value="2">
              <v-container fluid>
                 
                <ContactTab
                  :group="group"
                  ref="contacttab"
                  :selectedMember="selectedMember"
                  @update-member="getContacts"
                  @added-to-group="contactsAdded"
                />
              </v-container>
            </v-window-item>
            <v-window-item :value="3">
              <div class="d-flex w-100 justify-center">
                <div class="detail_tab">
                  <GroupDetail
                    :groupMemberCounts="groupMemberCounts"
                    :groupTypesList="groupTypesList"
                    :groupInfo="group"
                    ref="groupdetailtab"
                  />
                </div>
              </div>
            </v-window-item>
          </v-window>
        </div>
        <v-card-actions class="mt-auto">
          <v-spacer></v-spacer>
          <div class="d-flex justify-space-between w-100">
            <div>
              <v-btn
                v-if="isGroupEdit"
                :disabled="disabledSection"
                class="py-3 ml-3 action_btn_clr"
                @click="duplicateEntry"
              >
                Duplicate this group
              </v-btn>
            </div>
            <div class="d-flex">
              <v-btn
                class="mr-2 action_btn_clr"
                :disabled="disabledSection"
                @click="closeDialog"
              >
                CANCEL
              </v-btn>
              <v-btn
                class="action_btn_clr"
                @click="saveDetail"
                :disabled="disabledSave"
              >
                SAVE
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MemberTab from "@/components/group/MemberTab.vue";
import ContactTab from "@/components/group/ContactTab.vue";
import GroupDetail from "@/components/group/GroupDetail.vue";
export default {
  components: {
    MemberTab,
    ContactTab,
    GroupDetail,
    // S2gTable,
    // ConfirmPanel,
    // Remove,
    // Paginaiton,
  },
  props: {
    group: {
      default: () => {
        return {};
      },
      type: Object,
    },
    groupTypesList: {
      default: () => {
        return [];
      },
      type: Array,
    },
    defaultGroupMembers: {
      default: () => {
        return { selectedM: [], uselectedM: [] };
      },
      type: Object,
    },
    disableTabs: {
      default: () => {
        return {};
      },
      type: Object,
    },
    isGroupEdit: {
      default: () => {
        return true;
      },
      type: Boolean,
    },
  },
  computed: {
    selectedMember() {
      return {
        checkedMember: this.$refs.membertab?.checkedMember,
        uncheckedMember: this.$refs.membertab?.uncheckedMembers,
        isSelectAll:
          this.$refs.membertab?.isSelectAll ||
          this.$refs.membertab?.groupMembers?.length == 0,
      };
    },
  },
  data: () => ({
    dialog: true,
    loading: false,
    tab: null,
    activeTab: "member",
    groupMemberCounts: 0,
    disabledSection: false,
    disabledSave: false,
    isTab: false,
    selectedContacts: {},
    token: localStorage.getItem("tknds") || "",
  }),
  methods: {
    toggleTabs() {
      this.isTab = !this.isTab;
      this.disabledSave = true;
      this.disabledSection = true;
      console.log("disabledSection", this.disabledSection);
    },
    enableSave() {
      this.disabledSave = false;
      this.disabledSection = false;
    },
    toggleTabsEnable() {
      this.isTab = false;
      this.disabledSection = false;
    },
    closeDialog() {
      this.$emit("close-dialog");
      this.dialog = false;
    },
    duplicateEntry() {
      console.log("ddd", this.group);
      const req = {
        ClientId: this.$root.userdetail.user[0].ClientID,
        CreatedBy: this.$root.userdetail.user[0].UserID
      };
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      //  this.conversations = JSON.parse(item);
      fetch(`${API_URL}duplicate_group_members?id=${this.group.Id}`, {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("sdfs");
          // this.groupMembers = result;
          this.dialog = true;
          this.$emit("close-dialog");
          this.dialog = false;
          console.log(result);
          if(result.type=="DEACTIVATED") {
            return;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadMember() {
      console.log(this.$refs.contacttab?.checkedMember?.map((e) => e.UserID));
      this.selectedContacts = {
        checkedMember: this.$refs.contacttab?.checkedMember?.map(
          (e) => e.UserID
        ),
        uncheckedMember: this.$refs.contacttab?.uncheckedMembers?.map(
          (e) => e.UserID
        ),
        isSelectAll:
          this.$refs.contacttab?.isSelectAll ||
          this.$refs.contacttab?.groupMembers?.length == 0,
      };
      console.log(this.selectedContacts);
      if (this.activeTab != "member") {
        // this.$refs.membertab?.reset();
        setTimeout(() => {
          this.$refs.membertab?.getGroupmembers();
        }, 500);
      }
      this.activeTab = "member";
    },
    saveDetail() {
      if (this.isGroupEdit) {
        if (this.$refs.membertab && this.$refs.membertab.confirmDelete) {
          this.$refs.membertab?.removeAPi();
        }
        if (this.$refs.contacttab?.activateAddGroup) {
          this.$refs.contacttab?.addContactsToGroup();
        }
        this.$refs.groupdetailtab?.saveDetails();
         this.closeDialog();
      } else {
        if (this.$refs.membertab && this.$refs.membertab.confirmDelete) {
          this.$refs.membertab.unselectedMembers();
        }
        this.closeDialog();
      }
    },
    loadDetail() {
      this.activeTab = "detail";
    },
    loadContact() {
      this.activeTab = "contact";

      console.log(this.$refs.contacttab);
      //this.$refs.contacttab?.reset();
      this.$refs.contacttab?.getContacts();
    },
    groupMembers(e) {
      this.groupMemberCounts = e;
      this.$refs.contacttab?.getContacts();
    },
    getContacts(e) {
      this.$refs.membertab?.getGroupmembers();
    },
    contactsAdded(){
      this.$refs.membertab.isSelectAllTemp = false;
    }

    /*Manage checked Unchecked */
    /* */
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.group_member_dialog {
  min-height: 800px;
  min-width: 1000px;
}
.group-member {
  &__list {
    padding-top: 25px;
    padding-left: 170px;
    padding-right: 170px;
    ::v-deep {
      .v-slide-group {
        margin-bottom: 0.5em;
        justify-content: center;
        &__container {
          flex: none;
        }
      }
      .v-btn--variant-text .v-btn__overlay {
        background: #fff !important;
      }
    }
  }
  &__count {
    position: relative;
    top: 25px;
    left: 15px;
    color: #000;
    font-weight: 500;
    display: inline-block !important;
  }

  &__dialog {
  }
}
::v-deep {
  .v-tabs {
    height: 50px;
  }
  .v-pagination__list {
    margin: 0 !important;
    padding: 0 !important;
  }
}
.w-100 {
  width: 100%;
  margin-bottom: 2px;
}
::v-deep {
  .custom-btn {
    .v-btn__content {
      color: #000 !important;
      padding: 0px !important;
    }
    &.v-tabs {
      height: 30px;
    }
    .v-tab__slider {
      background-color: #000000 !important;
      bottom: -5px;
      height: 4px;
    }
    .v-tab.v-tab.v-btn {
      margin-top: 0px !important;
      color: #000 !important;
    }
    .v-btn {
      &:hover {
        .v-tab__slider {
          opacity: 1;
        }
      }
    }
  }
}
.search-box2 {
  ::v-deep {
    .v-field__outline,
    .v-input__details {
      display: none !important;
    }
  }
}
::v-deep {
  .selected_item {
    background: #eaf2fc;
  }
  
  .remove-confirm {
    .hover_item:not(.member_selected):not(.remove_selected) {
      &:hover {
        background: #fff;
      }
      .remove_outline_hover {
        display: none;
      }
      .remove_outline {
        display: block;
      }
    }
  }
   
  .remove_selected {
    background: #f5e0ab;
    opacity: 1;
    &:hover {
      background: #f5e0ab;
    }
    .remove_outline_hover {
      display: block;
    }
    .remove_outline {
      display: none;
    }
  }
}
.pagination {
  margin-left: -9px;
}
.detail_tab {
  width: 400px;
}
.action_btn_clr {
  color: #4783e6;
  text-transform: none !important;
}
</style>