<template>
  <div>
    <template v-if="isGroup">
      <div class="position-relative" v-if="showInfo">
        <div class="w-100 position-absolute info_panel_msg">
          <InfoPanel @close="showInfo = false" :panelClass="'warning_panel'">
            <p><strong>Confirmation: </strong>a group has been deleted.</p>
          </InfoPanel>
        </div>
      </div>
      <div class="selected_row_box" v-if="selectedItem && !searchVisible">
        <div class="d-flex justify-content-center position-relative">
          <div class="bulk_select">
            <label>{{selectedItem}} Selected</label>
            <label class="mx-5 link" @click="deleteAll"
              ><DeleteIcon /><span>Delete</span>
            </label>
            <label v-if="!batchActionActive" class="mx-5 link" @click="activeInactiveAll(true)"
              ><UserPlus /><GroupBatchActiveIcon /><span>Active</span></label
            >
            <label v-if="batchActionActive" class="mx-5 link" @click="activeInactiveAll(false)"
              ><UserPlus /><GroupBatchInactiveIcon/><span>Inactive</span></label
            >
            
            <label v-if="sharingEnabled" class="mx-5 link" @click="ShareAll"
              ><UserPlus /><ShareBatchIcon /><span>Share</span></label
            >
          </div>
        </div>
      </div>
      <div class="align-center justify-center">
      <S2gTable
        :indeterminate="indeterminate"
        :items="groups"
        :headers="groupListHeader"
        :isWhiteIcons="true"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
        ]"
        :thsearch="false"
        :dynamicSearchHeight="false"
        @search="search"
        @open-search="searchOpen"
        @column-filter="handleFilter"
        @toggle-row-select="toggleRowChecked"
        @refresh="reloadTableData"
        @select-all="selectAll"
        :totalItems="groupPagination.pager.totalItems"
        :totalCountText="'Groups'"
        :totalCountText1="'Group'"
        :showCount="true"
        :isSelectGlobalCheckBox="isSelectAll"
        :page="(groupPagination.currentPage - 1) * groupPagination.itemsPerPage"
        :disabledTable="!disabledTable"
      >
        <template v-slot:table_body="{ item }">
          <td>
            <span class="fixed-length-text pr-8" style="max-width: 173px">{{ item.item.Name }}</span>
          </td>
          <td>
            <div class="filter-cell">
              <span class="svg_icon">
              <component :is="item.item.Icon"></component>
            </span>
            {{ item.item.Group_Type }}
            </div>
          </td>
          <td class="text-right">{{ item.item.Member_Count }}</td>

          <td>
            <div class="filter-cell">
              <span></span>
              <div class="flex items-center">
                
                <SwitchOn
                class="cursor_pointer switch-icon-btn"
                v-if="item.item.Status == 'active'"
                @clicked="(e) => updateGroupStatus(e, item.item, 'inactive')"
                :model="item.item.Status == 'active'"
                />
                <SwitchOffInactive
                  class="cursor_pointer switch-icon-btn"
                  v-if="item.item.Status == 'inactive'"
                  @clicked="(e) => updateGroupStatus(e, item.item, 'active')"
                  :model="item.item.Status == 'active'"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="table-actions">
              <ShareListIcon v-if="item.item.Shared==1"/>
              <span @click="showGroupMembers(item.item)">
                <EditIcon class="icon-btn" />
              </span>
              <span class="" @click="showDeletePopup(item.item)">
                <DeleteIcon class="icon-btn" />
              </span>
            </div>
          </td>
        </template>
      </S2gTable>
    </div>
      <div class="ma-10">
        <Paginaiton
          v-if="groupPagination.pager.totalPages > 1"
          :currentPage="groupPagination.currentPage"
          :totalItems="groupPagination.pager.totalItems"
          :itemsPerPage="groupPagination.itemsPerPage"
          :totalPages="groupPagination.pager.totalPages"
          :disabled="!disabledTable"
          @page-changed="changePage"
        />
      </div>
      <GroupMemberDialog
        @close-dialog="closeDialog"
        :group="group"
        :groupTypesList="groupTypesList"
        v-if="dialog"
      />
    </template>
    <Confirmation
      v-model="confirmDialog"
      :data="group"
      @closed="confirmDialog = false"
      :onconfirm="(data) => confirmRemove()"
      :body="'Do you really want to delete this group?'"
    />
  </div>
</template>
<script >
const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
// import Switch from "@/components/common/Switch.vue";
import SwitchOn from "@/components/common/SwitchOn.vue";
import InfoPanel from "@/components/common/InfoPanel.vue";
// import SwitchOff from "@/components/common/SwitchOff.vue";
import SwitchOffInactive from "@/components/common/SwitchOffInactive.vue";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import CompanyIcon from "@/components/icons/Company";
import GroupIcon from "@/components/icons/Group";
import EventIcon from "@/components/icons/Event";
import PropertyIcon from "@/components/icons/Property";
import AreaIcon from "@/components/icons/Area";
import TeamIcon from "@/components/icons/MemberIcon";
import ProjectIcon from "@/components/icons/Project";
import LocationIcon from "@/components/icons/Location";
import S2gTable from "@/components/common/S2gTable.vue";
import GroupMemberDialog from "@/components/group/GroupMemberDialog.vue";
import Paginaiton from "@/components/common/Paginaiton.vue";
import Confirmation from "@/components/modals/Confirmation.vue";
import GroupBatchActiveIcon from "@/components/icons/share/GroupBatchActiveIcon.vue";
import GroupBatchInactiveIcon from "@/components/icons/share/GroupBatchInactiveIcon.vue";
import ShareBatchIcon from "@/components/icons/share/ShareBatchIcon.vue";
import ShareListIcon from "@/components/icons/share/ShareListIcon.vue";

import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";
import { Utils } from "@/helpers/util";

export default {
  props: {
    groupTypesList: [],
    disabledTable:Boolean,
  },
  components: {
    // Switch,
    SwitchOn,
    SwitchOffInactive,
    // SwitchOff,
    EditIcon,
    DeleteIcon,
    S2gTable,
    GroupMemberDialog,
    Paginaiton,
    Confirmation,
    CompanyIcon,
    GroupIcon,
    EventIcon,
    ProjectIcon,
    PropertyIcon,
    LocationIcon,
    TeamIcon,
    AreaIcon,
    InfoPanel,
    GroupBatchActiveIcon,
    GroupBatchInactiveIcon,
    ShareBatchIcon,
    ShareListIcon
  },
  data() {
    return {
      groupListHeader: [
        { key: "name", title: "Name" },
        {
          key: "Group_Type_Id",
          title: "Type",
          width: "30%",
          isFilter: true,
          filterItems: [],
        },
        { key: "total_member", title: "" },
        {
          key: "status",
          title: "Active",
          width: "15%", 
          isFilter: true,
          filterItems: [
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ],
        },
        { key: "action", title: "",  width: "15%", },

      ],
      groups: [],
      group: {},
      isGroup: false,
      confirmDialog: false,
      indeterminate: false,
      isSelectAll: false,
      showInfo: false,
      searchText: "",
      searchVisible: false,
      selectedItem: 0,
      editablecount: 0,
      token: localStorage.getItem("tknds") || "",
      groupPagination: {
        currentPage: 1,
        pager: { totalPages: 0, totalItems: 0 },
        itemsPerPage: 5,
      },
      groupFilter: { Group_Type_Id: null,status:undefined },
      uncheckedItems: [],
      checkedItems: [],
      batchActionActive: true,
      sharingEnabled:true,
    };
  },
  methods: {
    getGroups() {
      let url =
        API_URL +
        "groups/?page=" +
        this.groupPagination.currentPage +
        "&perpage=" +
        this.groupPagination.itemsPerPage +
        "&search=" +
        this.searchText;
        url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
        url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
        url += "&Role=" + this.$root.userdetail.user[0].Role;
      if (this.groupFilter.Group_Type_Id) {
        url += "&Group_Type_Id=" + this.groupFilter.Group_Type_Id;
      }
      if (this.groupFilter.status) {
        url += "&status=" + this.groupFilter.status;
      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          //console.log('ALL GROUPS : ', result);
          console.log(this.uncheckedItems, this.checkedItems);
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          } else {
            console.log("result.data = ", result.data)
            this.groups = result.data?.map((e) => {
              //e.checked = this.isSelectAll;
              e.checked = manageRowDefaultSelection(
                this.isSelectAll,
                e,
                this.checkedItems,
                this.uncheckedItems,
                "Id"
              );
              //e.checked = true;
              if(e.Default_Group == "1" || (e.Shared && e.CreatedBy != this.$root.userdetail.user[0].UserID)){
                //e.class = "disabled"
                e.checked = false;
                e.disable_checkbox = e.Default_Group == "1" || (e.Shared && e.CreatedBy != this.$root.userdetail.user[0].UserID);
              }
              e.class = e.Status == "inactive" ? "hover_item danger_text" : "hover_item";
              return e;
            });
            if (this.groups.length > 0) {
              this.isGroup = true;
            }
            this.editablecount = result.editablecount;
            this.groupPagination.pager = result.pager;
            this.sharingEnabled = result.sharingEnabled;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reloadTableData(e) {
      this.groups = [];
      this.group = {};
      this.confirmDialog = false;
      this.indeterminate = false;
      this.isSelectAll = false;
      this.showInfo = false;
      this.searchText = "";
      this.selectedItem = 0;
      this.groupFilter = { Group_Type_Id: null };
      this.uncheckedItems = [];
      this.checkedItems = [];
      this.batchActionActive = true;
      this.getGroups();

    },
    toggleRowChecked(e) {
      const event = e.e;
      this.indeterminate = false;
      this.searchVisible = false;
      //update checked property
      const u = manageSingleChecked(
        event,
        e.item,
        "Id",
        this.groups,
        this.selectedItem,
        this.isSelectAll,
        this.groupPagination.totalItems
      );
      this.selectedItem = u.selectedCounts;
      this.indeterminate = u.indeterminate;
      this.isSelectAll = u.isSelectAll;
      if (event.target.checked) {
        e.item.checked = event.target.checked;
      } 
      
      this.manageClass()
      
      manageSelectedItems(event, e.item, "Id", this.checkedItems);
      manageUnSelectedItems(event, e.item, "Id", this.uncheckedItems);
      //alert(JSON.stringify(this.checkedItems))
    },
    manageClass(){
      this.groups = this.groups.map((e) => {
        if (e.Default_Group == 1){
          return e; 
        }
        e.class = e.Status == "inactive" ? "hover_item danger_text" : "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        
        return e;
      });
    },
    selectAll(event) {
      this.checkedItems = [];
      this.uncheckedItems = [];
      this.isSelectAll = event.target.checked;
      this.indeterminate = false;
      this.searchVisible = false;
      if (this.isSelectAll) {
        this.selectedItem = this.editablecount;
        this.checkedItems = [];
        this.uncheckedItems = [];
        this.indeterminate = false;
      } else {
        this.selectedItem = 0;
        this.indeterminate = false;
      }
      this.groups = this.groups.map((e) => {
        e.checked = e.Default_Group != "1" && event.target.checked && !(e.Shared && e.CreatedBy != this.$root.userdetail.user[0].UserID);
        return e;
      });
      this.manageClass()
      //this.selectedItem = (this.isSelectAll) ? this.editablecount : 0;
      
    },
    batchActionData(){
      return {
        "isSelectAll": this.isSelectAll,
        "checkedItems": this.checkedItems,
        "uncheckedItems": this.uncheckedItems,
        "ClientID": this.$root.userdetail.user[0].ClientID,
        "CreatedBy": this.$root.userdetail.user[0].UserID,
        "Role": this.$root.userdetail.user[0].Role,
        "batchActionActive": this.batchActionActive
      }
    },
    deleteAll(){
      let data = this.batchActionData();
        data.action = "delete";
        this.requstBatchAction(data);
    },
    activeInactiveAll(active){
      this.batchActionActive = !this.batchActionActive;
      let data = this.batchActionData();
        data.action = "status";
        this.requstBatchAction(data);
    },
    ShareAll(){
      let data = this.batchActionData();
        data.action = "share";
        this.requstBatchAction(data);
    },
    requstBatchAction(data){
      let url = API_URL + "groupsbatchaction";
      fetch(url, {
        method: "POST",
        body:JSON.stringify(data),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
      .then((response) => response.json())
      .then((result) => {
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          } else {
            this.getGroups();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    search(val) {
      this.searchText = val;
      this.getGroups();
    },
    searchOpen(e) {
      this.searchVisible = true;
    },
    selectGroupType(e) {
      console.log(e);
      this.groupData.groupType = e.value;
    },
    handleFilter(e) {
      console.log(e);
      try{
      const event = e.e;
      if (!e.column) {
        if (event.value == "all") {
          this.selectAll({ target: { checked: true } });
        } else {
          this.selectAll({ target: { checked: false } });
        }
      } else {
        this.groupPagination = {
          currentPage: 1,
          pager: { totalPages: 0, totalItems: 0 },
          itemsPerPage: 5,
        };
        //console.log(e.column.key)
        this.groupFilter[e.column.key] = e.e.value;
        console.log('000', this.groupFilter)
        this.getGroups();
      }
       }catch(e){
        console.log(e)
      }
    },
    showGroupMembers(item) {
      this.group = item;
      this.dialog = true;
      console.log(item);
      this.$forceUpdate();
    },
    closeDialog() {
      this.group = null;
      this.getGroups();
      this.dialog = false;
    },
    showDeletePopup(item) {
      console.log("saas", item);
      this.member_id = item.Id;
      this.confirmDialog = true;
      this.$forceUpdate();
    },
    confirmRemove() {
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      //  this.conversations = JSON.parse(item);
      fetch(`${API_URL}delete_group?id=${this.member_id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.confirmDialog = false;
          if(result.type=="DEACTIVATED") {
            Utils.openAccountDeactivatedAlert(window, {context: "Groups"})
            return;
          }
          this.reloadTableData();
          this.getGroups();

          this.showInfo = true;
          setTimeout((e) => {
            this.showInfo = false;
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changePage(page) {
      this.groupPagination.currentPage = page;
      this.getGroups();
    },
    updateGroupStatus(e, val, status) {
      console.log(val);
      val.loading = true;
      fetch(API_URL + "update_group_status", {
        method: "PUT",
        body: JSON.stringify({
          // status: e.target.checked ? "active" : "inactive",
          status: status,
          id: val.Id,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          val.loading = false;

          this.getGroups();
          console.log(result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.getGroups();
    //console.log("=============", this.groupListHeader, this.groupTypesList);
    this.groupListHeader = this.groupListHeader.map((e) => {
      if (e.isFilter && e.key == "Group_Type_Id") {
        e.filterItems = this.groupTypesList;
      }
      return e;
    });
    console.log('----', this.groupListHeader)
  },
};
</script>
 <style scoped lang="scss">
  
 
::v-deep {
  .danger_text {
    td {
      color: #b10000;
      &:not(:first-child) {
        .svg_icon{
        svg {
          path {
            stroke: #b10000;
          }
          path.fill {
            fill: #b10000;
            stroke: transparent;
          }
          path.white {
            fill: white;
            stroke: transparent;
          }
          rect {
            stroke: #b10000;
          }
        }
        }
      }
    }
  }
  .warning_panel {
    background: #f5e0ab;
  }
}
.info_panel_msg {
  top: 1px;
}

.switch-icon-btn{
  line-height: 0;
  display: inline-flex;
  align-items: center;
}
.selected_row_box {
  margin-bottom: 10px;
}
.bulk_select{
  width: 642px;
}
 
</style>