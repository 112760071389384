<template>
  <div class="messages-page">
    <div class="content-container">

      <div class="pt-3 d-flex align-items-center justify-content-end messages-page-header">
        <!-- <div  v-if="showSearch" class="customsearch" ref="searchContainer">
            <input type="search" v-model="search" placeholder="Search..." >
            <button type="button" @click="clearInput" class="clear-btn">
              <svg class="default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg>
            <svg class="hovered" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="3" width="18" height="18" rx="4" fill="#E0E0E0"/>
              <path d="M18.3642 7.05025L13.4144 12L18.3642 16.9497L16.95 18.364L12.0002 13.4142L7.05048 18.364L5.63627 16.9497L10.586 12L5.63627 7.05025L7.05048 5.63604L12.0002 10.5858L16.95 5.63604L18.3642 7.05025Z" fill="#7B8794"/>
            </svg></button>
            <button type="button"  @click="searchContents">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 18C15.1421 18 18.5 14.6421 18.5 10.5C18.5 6.35786 15.1421 3 11 3C6.85786 3 3.5 6.35786 3.5 10.5C3.5 14.6421 6.85786 18 11 18Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.4998 21L16.2998 15.8" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div> -->
        <div class="d-flex align-items-center">
          <!-- <button type="button" class="search-btn" ref="searchButton" :class="{ hide: showSearch }"  @click="toggleSearch">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.9998 21L15.7998 15.8" stroke="#7B8794" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button> -->
          <router-link :to="{ name: 'ComposeMessage' }" class="btn btn-primary rounded-pill new-message-btn">
            <AddIcon class="add-icon" />
            New Message
          </router-link>
        </div>
      </div>

      <div class="message-delete-alert-con" v-if="success">
        <div class="alert alert-dismissible alert-delete">
          <button type="button" class="close" data-dismiss="alert" @click="success = ''"
            style="color:#828282">&times;</button>
          <span class="alert-headeing">Confirmation</span><br>
          {{ success }}
        </div>
      </div>

      <!-- Block added to show the Auth Error. -->
      <!--
            Block added to show the Auth Error.
            <div cols="12" sm="6" md="6" v-if="serverError">
                <div class="servererror">{{serverError}}</div>
            </div> 
        -->
      <div class="selected_row_box" v-if="selectedMessages > 0 && !searchVisible">
        <div class="d-flex justify-content-center position-relative">
          <div class="bulk_select">
            <label>{{ selectedMessages }} Selected</label>
            <label class="link" @click="deleteAll">
              <DeleteIcon /><span>Delete</span>
            </label>
          </div>
        </div>
      </div>


      <div v-if="!noContacts" class="messages-table align-center justify-center">
        <S2gTable :indeterminate="indeterminate" :items="ourcontents" :selectedItemCount="selectedMessages"
          ref="tableRef" :isWhiteIcons="true" :headers="messagesheader" :searchText="search" :filterItems="[
            { label: 'All', value: 'all' },
            { label: 'None', value: 'none' },
            { label: 'Active', value: 'active' },
            { label: 'Expired', value: 'expired' },
            { label: 'Future', value: 'future' }
          ]" @column-filter="handleFilter" @toggle-row-select="selectMessages" :thsearch="false" :showCount="true"
          :isReload="true" @search="searchTeams" @open-search="searchOpen" @refresh="onResetClicked"
          :totalItems="`${pager.totalItems}`" :totalCountText="'Messages'" :totalCountText1="'Message'"
          :dynamicSearchHeight="false" :isSelectGlobalCheckBox="isSelectAll" @select-all="selectAll"
          :page="(currentPage - 1) * itemsPerPage">

          <template v-slot:table_body="{ item }">
            <td>
              <div class="fixed-length-text" style="max-width: 340px;">
                {{ item.item.Body ? item.item.Title + ' - ' + item.item.Body : item.item.Title }}
              </div>
            </td>
            <td>
              <div class="fixed-length-text" style="max-width: 259px;">
                {{ item.item.Location }}
              </div>
            </td>
            <td>
              <div class="filter-cell"><span></span> <span>{{ radiusValues(item.item) }}</span></div>
            </td>


            <td v-if="item.item.Mode == 1">
              <div class="filter-cell"><span></span> <span v-html="modifiedDate(item.item.StartDate, true)"></span>
              </div>
            </td>

            <td v-if="item.item.Mode != 1">
              <div class="filter-cell"><span></span> <span>{{ modifiedDate(item.item.StartDate) }}</span></div>
            </td>

            <td>
              <div class="filter-cell"><span></span> <span v-html="isExpired(item.item)"></span></div>
            </td>




            <td>
              <div class="table-actions">
                <MessageSettingsPreview class="setting-icon" value="true" :content="item.item" />
                <span class="icon-btn">
                  <router-link class="action-button" :to="{ name: 'ComposeMessage', query: { id: item.item.ContentID } }">
                    <EditIcon />
                  </router-link>
                </span>
                <span class="icon-btn" @click="deleteContent(item.item.ContentID)">
                  <DeleteIcon />
                </span>
              </div>
            </td>
          </template>
        </S2gTable>
      </div>





      <Paginaiton v-if="pager.totalPages > 1" :currentPage="currentPage" :totalItems="pager.totalItems"
        :itemsPerPage="itemsPerPage" :totalPages="pager.totalPages" @page-changed="changePage" />

    </div>
    <Confirmation v-model="showConfirmationModal" :data="confirmationData" @closed="showConfirmationModal = false"
      :onconfirm="(data) => deleteConfirmed(data)" :body="deleteMsg" />
    <!-- <Confirmation
        v-model="showBulkDeleteConfirmationModal"
        @closed="showBulkDeleteConfirmationModal = false"
        :onconfirm="(data) => deleteConfirmed()"
        :body="deleteMsg"
      /> -->
    <!-- <Confirmation v-model="showConfirmationModal" :data="confirmationData" @closed="showConfirmationModal = false" :onconfirm="(data) => deleteConfirmed(data)"
          :body="'Do you really want to delete this message?'" /> -->
  </div>

</template>

<script>
let radiusObj = [[
  { 'label': '100 yd', 'value': '.057', 'zoom': '18' },
  { 'label': '200 yd', 'value': '.12', 'zoom': '17' },
  { 'label': '1/2 mi', 'value': '.5', 'zoom': '14.5' },
  { 'label': '1 mi', 'value': '1', 'zoom': '13.5' },
  { 'label': '2 mi', 'value': '2', 'zoom': '12.5' },
  { 'label': '3 mi', 'value': '3', 'zoom': '12' },
  { 'label': '5 mi', 'value': '5', 'zoom': '11.5' },
  { 'label': '10 mi', 'value': '10', 'zoom': '10.5' },
  { 'label': '20 mi', 'value': '20', 'zoom': '9.5' },
  // { 'label':'Region Free'  , 'value':'0' , 'zoom' : '5'},
], [
  { 'label': '100 m', 'value': '.10', 'zoom': '18' },
  { 'label': '200 m', 'value': '.20', 'zoom': '17' },
  { 'label': '500 m', 'value': '.5', 'zoom': '15.5' },
  { 'label': '1 km', 'value': '1', 'zoom': '14.5' },
  { 'label': '2 km', 'value': '2', 'zoom': '13.5' },
  { 'label': '5 km', 'value': '5', 'zoom': '12' },
  { 'label': '10 km', 'value': '10', 'zoom': '11' },
  { 'label': '15 km', 'value': '15', 'zoom': '10' },
  { 'label': '20 km', 'value': '20', 'zoom': '10' },
  { 'label': '25 km', 'value': '25', 'zoom': '9.5' },
  { 'label': '30 km', 'value': '30', 'zoom': '9' },
  // { 'label':'Region Free'  , 'value':'0' , 'zoom': '5'},
]]
import { format } from 'date-fns';

const API_URL = process.env.VUE_APP_APIDOMAIN + "/ourcontents";
import Paginaiton from './components/common/Paginaiton.vue';
import EditIcon from './components/icons/EditIcon'
import DeleteIcon from './components/icons/DeleteIcon'
import AddIcon from './components/icons/AddIcon'
//import ResetIcon from './components/icons/ResetIcon.vue'
import Confirmation from './components/modals/Confirmation.vue'
//import DropdownFilter from './components/common/DropdownFilter.vue'
import MessageSettingsPreview from './components/common/MessageSettingsPreview.vue'
import S2gTable from "@/components/common/S2gTable.vue";
import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";
import { Utils } from './helpers/util';

export default {
  name: "ourcontent",
  components: { Paginaiton, EditIcon, DeleteIcon, AddIcon, Confirmation, MessageSettingsPreview, S2gTable },
  data: () => ({
    pager: {},
    error: "",
    search: "",
    filter: { "key": "", "value": "" },
    sort: "Mode,Modified",
    order: "DESC",
    serverError: "",
    success: "",
    currentPage: 1,
    itemsPerPage: 10,
    ourcontents: [],
    showSearch: false,
    isSelectAll: false,
    selectedMessages: [],
    searchVisible: false,
    uncheckedMessages: [],
    checkedMessages: [],
    selectAllFilter: "",
    showConfirmationModal: false,
    //showBulkDeleteConfirmationModal: false,
    confirmationData: {},
    expire_count: 0,
    token: localStorage.getItem("tknds") || '',

    sentFilterOptions: [
      { label: 'Planned', value: "Planned" },
      { label: 'Past 3 Days', value: "Past3Days" },
      { label: 'This Week', value: "ThisWeek" },
      { label: 'This Month', value: "ThisMonth" },
      { label: 'Before this Month', value: "BeforethisMonth" },

    ],
    /* durationFilterOptions:[
      { 'ms':'1814400000'    , 'label':'1/2 Day' , value:'halfday'},
      { 'ms':'86400000'     , 'label':'1 Day', value:'1day'},
      { 'ms':'172800000'    , 'label':'2 Days', value:'2days'},
      { 'ms':'259200000'    , 'label':'3 Days' , value:'3days'},
      { 'ms':'604800000'    , 'label':'1 Week' , value:'1week'},
      { 'ms':'1209600000'    , 'label':'2 Weeks' , value:'2weeks'},
      { 'ms':'1814400000'    , 'label':'3 Weeks' , value:'3weeks'},
      { 'ms':'1814400000'    , 'label':'1 Month' , value:'1month'},
      { 'ms':'1814400000'    , 'label':'Over 30 Days' , value:'over30days'},
      { 'ms':'0'            , 'label':'No Limit', value:'noLimit'}
    ], */
    messagesheader: [
      { key: "name", title: "Name", class: "pr-0" },
      { key: "location", title: "Geofence Location", class: "pr-0" },
      // { key: "email", title: "Email", class: "pr-0" },
      {
        key: "radius",
        title: "Radius",
        class: "pr-0",
        isFilter: true,
        filterItems: radiusObj[localStorage.getItem("operator_radius_unit") - 1],
      },
      {
        key: "timesent",
        title: "Start date",
        class: "pr-0 startDateHeader",
        isFilter: true,
        filterItems: [
          { label: 'Planned', value: "Planned" },
          { label: 'Past 3 Days', value: "Past3Days" },
          { label: 'This Week', value: "ThisWeek" },
          { label: 'This Month', value: "ThisMonth" },
          { label: 'Before this Month', value: "BeforethisMonth" },
        ],
      },
      {
        key: "duration",
        title: "Duration",
        class: "pr-0",
        isFilter: true,
        filterItems: [
          { 'ms': '1814400000', 'label': '1/2 Day', value: 'halfday' },
          { 'ms': '86400000', 'label': '1 Day', value: '1day' },
          { 'ms': '172800000', 'label': '2 Days', value: '2days' },
          { 'ms': '259200000', 'label': '3 Days', value: '3days' },
          { 'ms': '604800000', 'label': '1 Week', value: '1week' },
          { 'ms': '1209600000', 'label': '2 Weeks', value: '2weeks' },
          { 'ms': '1814400000', 'label': '3 Weeks', value: '3weeks' },
          { 'ms': '1814400000', 'label': '1 Month', value: '1month' },
          { 'ms': '1814400000', 'label': 'Over 30 Days', value: 'over30days' },
          { 'ms': '0', 'label': 'No Limit', value: 'noLimit' }
        ],
      },

      { key: "setting", title: "Settings" },
    ],
  }),
  computed: {
    /* paginatedContents() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.ourcontents.slice(start, end);
    } */
  },
  beforeCreate() {
    if (this.$route.fullPath == '/') {
      this.$router.push('/messages');
    }
  },
  mounted() {
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    searchContents() {
      if (this.search.trim() != "") {
        let query = Object.assign({}, this.$route.query);
        //delete query.page;
        delete query.filterkey;
        delete query.filtervalue;
        this.$router.replace({ query });
        this.$router.push({ query: Object.assign({}, query, { search: this.search }) });
      } else {
        let query = Object.assign({}, this.$route.query);
        delete query.search;
        this.$router.replace({ query });
      }
    },
    selectAll(event) {
      this.searchVisible = false;
      this.isSelectAll = event.target.checked;
      this.ourcontents = this.ourcontents.map((e) => {
        e.checked = event.target.checked;
        e.checkdClass = "";
        e.class = "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        return e;
      });
      this.uncheckedMessages = [];
      this.checkedMessages = [];
      if (this.isSelectAll) {
        this.selectedMessages = this.pager.totalItems;
        this.checkedMessages = [];
        this.uncheckedMessages = [];
        this.indeterminate = false;
      } else {
        this.selectedMessages = 0;
        this.indeterminate = false;
      }
    },
    filterContents() {
      if (this.filter.value != "") {
        let query = Object.assign({}, this.$route.query);
        this.getQueryData(true);
        //delete query.search;
        //this.$router.replace({ query });
        //this.$router.push({ query: Object.assign({}, query, { filterkey: this.filter.key, filtervalue: this.filter.value }) });        
      }
    },
    getAddress(lat, lon) {
      return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyAQSTYokUOoCFYRwRWkExTvi0uteiIrF1o`).then(result => result.json()).then(result => {
        if (result && result.results.length) {
          return result.results[0].formatted_address
        }
      })
    },
    truncateText(text, length) {
      if (!text) { return text }
      return 'Char count ' + String(text).length;
      //if (String(text).length < length){ return text }
      //return String(text).substring(0, length) + '...';
    },
    radiusValues(val) {
      let db_radius = val.Radius_Unit == 1 ? Number(val.Radius / 1609.344) : Number(val.Radius / 1000);
      //round up to 2 decimal
      let view_radius = Math.round(db_radius * 100) / 100;
      let unit = val.Radius_Unit == 1 ? 'mi' : 'km';
      // odd mile values
      if (val.Radius_Unit == 1) {
        //alert('view_radius :: '+ view_radius)
        if (Number(view_radius) == .25) {
          return '1/4 ' + unit;
        } else if (Number(view_radius) == .50) {
          return '1/2 ' + unit;
        } else if (Number(view_radius) == .06) {
          return '100 yd';
        } else if (Number(view_radius) == .12) {
          return '200 yd';
        }
      }

      //odd km values
      if (val.Radius_Unit == 2) {
        if (Number(view_radius) == .1) {
          return '100 m';
        } else if (Number(view_radius) == .20) {
          return '200 m';
        } else if (Number(view_radius) == .5) {
          return '500 m';
        }
      }

      //R-free
      if (view_radius == 0) {
        return 'R-free';
      }

      // all int values
      return view_radius + ' ' + unit;

    },
    orderBy(col) {
      if (this.sort == col && this.order == "ASC") {
        this.order = "DESC"
      } else {
        this.order = "ASC"
      }
      this.sort = col;

      let query = Object.assign({}, this.$route.query);
      delete query.sort;
      delete query.order;
      this.$router.replace({ query });
      this.$router.push({ query: Object.assign({}, this.$route.query, { order: this.order, sort: this.sort }) });

    },
    deleteAll() {
      this.deleteMsg = `Are you sure want to delete ${this.selectedMessages} messages`;
      //this.showBulkDeleteConfirmationModal = true;
      this.showConfirmationModal = true;
      this.confirmationData = { singleDelete: false }
    },
    // deleteContent(id){
    //   if(!confirm("Do you really want to delete this message?")){ return;}
    //   this.ourcontents = this.ourcontents.filter(function( obj ) {
    //     return obj.ContentID !== id;
    //   });
    //   fetch(API_URL, {
    //     method: "POST",
    //     body: JSON.stringify({deleteRow: id}),
    //     headers: {
    //       "content-type": "application/json"
    //     }
    //   }).then(response => response.json())
    //     .then(result => {
    //         if (result.details) {
    //           // there was an error...
    //           const error = result.details
    //             .map(detail => detail.message)
    //             .join(". ");
    //           this.error = error;
    //         } else {
    //           this.error = "";
    //           this.success = "Content Record Deleted!"; 
    //           this.ourcontents = this.ourcontents.filter(function( obj ) {
    //               return obj._id !== id;
    //           });             
    //           setTimeout(function(){
    //               window.location.reload();
    //           }, 2000)               
    //         }
    //       });
    // },
    deleteContent(id) {
      this.showConfirmationModal = true;
      this.deleteMsg = "Do you really want to delete this message?";
      this.confirmationData = { id: id, singleDelete: true }
    },
    deleteConfirmed(data) {
      //alert(this.selectAllFilter)
      this.showConfirmationModal = false;
      const req = {
        isAllSelected: this.isSelectAll,
        selectedFilterKey: this.filter.key,
        selectedFilterValue: this.filter.value,
        statusFilter: this.statusFilter,
        querytime: this.getDateForSql(new Date()),
        selectedMessages: this.checkedMessages.map((e) => e.ContentID),
        unselectedMessages: this.uncheckedMessages.map((e) => e.ContentID),
        ClientID: this.$root.userdetail.user[0].ClientID,
        Role: this.$root.userdetail.user[0].Role,
        SenderID: this.$root.userdetail.user[0].UserID,
        singleDelete: false
      };
      if (data.singleDelete) {
        req.singleDelete = data.id;
      }

      fetch(API_URL, {
        method: "DELETE",
        body: JSON.stringify(req),
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer " + this.token
        }
      }).then(response => response.json())
        .then(result => {
          //Auth Token Failed          
          if (result.AuthSuccess == 0) {
            this.serverError = result.message
            this.$root.logout();
          } else {

            if (result.type == "DEACTIVATED") {
              Utils.openAccountDeactivatedAlert(window, { context: "Messages" })
              return;
            }
            if (result.details) {
              // there was an error...
              const error = result.details
                .map(detail => detail.message)
                .join(". ");
              this.error = error;
            } else {
              this.error = "";
              this.success = "A message has been deleted and will be removed from the mobile device of the Receivers.";
              setTimeout(
                () => { this.success = ''; }, 2000
              )
              this.reset();
              this.getQueryData();
            }
          }

        });
    },
    modifiedDate(raw, draft = false) {
      if (raw === "") {
        return raw;
      }
      const date = new Date(raw);
      const result = new Date(date.toISOString().slice(0, -1));

      if (draft) {
        return '<font color="#b20101"> (draft)</font>';
      }

      const options = {
        month: 'short',
        day: '2-digit',
      };

      return result.toLocaleDateString('en-US', options); // Format as a local date string
    },
    // modifiedDate(raw, draft = false) {
    //   if (raw === "") {
    //     return raw;
    //   }
    //   const d = new Date(raw).getTime() + (new Date().getTimezoneOffset() * 60 * 1000);
    //   if (draft) { return /* (date.getMonth()+1)+'/'+date.getDate()+ */'<font color="#b20101"> (draft)</font>' }
    //   const options = {
    //     month: 'short',
    //     day: '2-digit'
    //   };
    //   return new Date(d).toLocaleDateString('en-US', options);

    //   //return format(date, "MMM d - hh:mm a");
    //   //return format(date, "MMM d", { timeZone: 'UTC' });
    //   //return date;
    // },
    isExpired(content) {
      //Don't Show Duration of Draft Messages
      if (content.Mode == 1) {
        return '';
      }
      let offset_time = new Date().getTimezoneOffset() * 60000;
      let Expiration_Timestamp = new Date(content.Expiration_Timestamp).getTime();
      let ExpriationTime = new Date(Expiration_Timestamp + offset_time).getTime();
      let expired = ExpriationTime < new Date().getTime() && content.Expiration_Duration != "No Limit";
      //let expired = content.is_expired == "1";
      return expired ? '<font color="#b20101">Expired</font> ' : content.Expiration_Duration;
    },
    getQueryData(resetpage = false) {
      //alert("getQueryData")
      //let search = this.$route.query.search || "";
      let page = parseInt(this.$route.query.page) || 1;
      //let filterkey =  this.$route.query.filterkey || "";
      //let filtervalue =  this.$route.query.filtervalue || "";
      //let status = this.$route.query.status || "";
      let status = this.statusFilter;

      this.currentPage = (resetpage) ? 1 : page;
      //var url = API_URL+'?page='+ page;
      let url = API_URL + '?page=' + page + '&perpage=' + this.itemsPerPage;

      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      url += "&SenderID=" + this.$root.userdetail.user[0].UserID;
      if (status) {
        url += "&status=" + status;
      }
      url += "&querytime=" + this.getDateForSql(new Date());

      if (this.search != "") {
        //this.search = search;
        url += '&search=' + this.search;
      }
      if (this.filter.value != "") {
        url += '&filterkey=' + this.filter.key + '&filtervalue=' + this.filter.value;
      }
      if (this.sort) {
        url += '&sort=' + this.sort;
        url += '&order=' + this.order;
      }

      //Promise.all(
      fetch(url, {
        method: 'GET',
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer " + this.token
        }
      })
        .then(response => response.json())
        .then(async (result) => {
          //Auth Token Failed     
          console.log(result);
          if (result.AuthSuccess == 0) {
            this.serverError = result.message
            this.$root.logout();
          } else {
            this.pager = result.contents.pager;
            this.expire_count = result.contents.expire_count || 0;
            this.active_count = result.contents.active_count || 0;
            this.future_count = result.contents.future_count || 0
            if (this.statusFilter == "expired") {
              this.selectedMessages = Number(this.expire_count) - this.uncheckedMessages.length;
            }
            if (this.statusFilter == "active") {
              this.selectedMessages = Number(this.active_count) - this.uncheckedMessages.length;
            }
            if (this.statusFilter == "future") {
              this.selectedMessages = Number(this.future_count) - this.uncheckedMessages.length;
            }

            this.ourcontents = result.contents.docs?.map((e) => {
              e.checked = this.isSelectAll;
              if (this.checkedMessages.some((o) => o.ContentID == e.ContentID)) {
                e.checked = true;
              }
              if (this.statusFilter == "expired") {
                e.checked = e.is_expired == 1;
              }
              // alert(e.is_active)
              if (this.statusFilter == "active") {
                e.checked = e.is_active == 1;
              }
              if (this.statusFilter == "future") {
                e.checked = e.is_future == 1;
              }

              if (this.uncheckedMessages.some((o) => o.ContentID == e.ContentID)) {
                e.checked = false;
              }
              e.class = "hover_item";
              e.checkdClass = e.checked ? "member_selected" : "";
              return e;
            });
            //console.log("this.ourcontents = ", this.ourcontents)
            this.manageSelection();
            //this.pager = result.teams.pager;

          }
        })
      //)
    },
    onSentFilterOptions() {
      this.filterContents();
    },
    /* resetSearchAndFilter(){
        let query = Object.assign({}, this.$route.query);
        delete query.search;
        delete query.filterkey;
        delete query.filtervalue;
        this.$router.push({ query: Object.assign({}, query, { }) });        

    }, 
    onDurationFilterOptions(){
      this.filter.key = "duration";
      this.filter.value = data.value;
      this.filterContents();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        this.search = ""; 
      }
    },
    clearInput() {
      this.search = ""; 
    },*/
    getDateForSql(dateTime) {
      // Format the Date object to the desired output
      let year = dateTime.getFullYear();
      let month = ('0' + (dateTime.getMonth() + 1)).slice(-2); // Months are zero-based
      let day = ('0' + dateTime.getDate()).slice(-2);
      let hours = ('0' + dateTime.getHours()).slice(-2);
      let minutes = ('0' + dateTime.getMinutes()).slice(-2);
      let seconds = ('0' + dateTime.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    changePage(newPage) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { page: newPage, perpage: this.itemsPerPage }) });
      this.currentPage = newPage;
    },
    /* handleClickOutside(event) {
      if(!this.showSearch){
        return;
      }
      if (!this.$refs.searchContainer.contains(event.target) && 
          !this.$refs.searchButton.contains(event.target)) {
          this.showSearch = false;
      }
    }, */
    handleFilter(e) {
      this.reset();

      if (e.column) {
        this.filter.key = e.column.key;
        this.filter.value = e.e.value;
        if (this.filter.key == "radius") {
          this.filter.value = Math.round(this.filter.value * localStorage.getItem("operator_radius"));
        }
        this.filterContents(true);
      } else {
        if (e.e.value == "expired" || e.e.value == "active" || e.e.value == "future") {
          if (e.e.value == "expired") {
            this.sort = "is_expired DESC, Expiration_Timestamp ";
          } else if (e.e.value == "active") {
            this.sort = "is_active DESC, Expiration_Timestamp ";
          } else {
            this.sort = "is_future DESC, Expiration_Timestamp ";
          }

          this.order = "DESC"
          this.checkedMembers = [];
          this.uncheckedMessages = [];
          this.statusFilter = e.e.value;
          this.isSelectAll = false;
          this.indeterminate = false;
          this.getQueryData(true);
        } else {
          //this.selectAllFilter = e.e.value;
          if (e.e.value == "none") {
            this.isSelectAll = false;
            this.selectedMessages = 0;
            this.checkedMessages = [];
            this.uncheckedMessages = [];
            this.selectAll({ target: { checked: false } });
            this.manageSelection();
          } else if (e.e.value == "all") {
            this.isSelectAll = true;
            this.selectAll({ target: { checked: true } });
          }
          //this.selectAllFilter = e.e.value;
          //this.currentPage = 1;
        }
        /* this.$router.push({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
            perpage: this.itemsPerPage,
            status: this.statusFilter,
            search: this.search,
            selectAllSort: this.selectAllFilter,
          }),
        }); */
        //this.getQueryData();
        // this.$router.push({ query: Object.assign({}, this.$route.query, { page: this.currentPage , perpage: this.itemsPerPage, status:this.statusFilter, selectAllSort:this.selectAllFilter}) });
        // this.getQueryData()

        //
        //}
      }
    },
    manageSelection() {
      this.ourcontents = this.ourcontents.map((e) => {
        e.checked = this.isSelectAll;

        if (this.checkedMessages.some((o) => o.ContentID == e.ContentID)) {
          e.checked = true;
        }
        if (this.statusFilter == "expired") {
          e.checked = e.is_expired == 1;
        }
        if (this.statusFilter == "active") {
          e.checked = e.is_active == 1;
        }
        if (this.statusFilter == "future") {
          e.checked = e.is_future == 1;
        }
        if (this.uncheckedMessages.some((o) => o.ContentID == e.ContentID)) {
          e.checked = false;
        }
        e.class = "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        return e;
      });
    },
    selectMessages(e) {
      const event = e.e;
      this.indeterminate = false;
      this.searchVisible = false;
      const u = manageSingleChecked(
        event,
        e.item,
        "ContentID",
        this.ourcontents,
        this.selectedMessages,
        this.isSelectAll,
        this.pager.totalItems
      );
      //console.log(u);
      this.selectedMessages = Number(u.selectedCounts);
      this.isSelectAll = u.isSelectAll;
      this.indeterminate = u.indeterminate;
      if (event.target.checked) {
        e.item.checked = event.target.checked;
      } else {
        // if (this.isSelectAll) {
        //   this.indeterminate = true;
        // }
      }

      manageSelectedItems(event, e.item, "ContentID", this.checkedMessages);
      manageUnSelectedItems(event, e.item, "ContentID", this.uncheckedMessages);
      this.manageGlobalSelection();
      this.ourcontents = this.ourcontents.map((e) => {
        e.checkdClass = "";
        if (e.checked) {
          e.checkdClass = "member_selected";
        }
        e.class = "hover_item";
        return e;
      });
    },
    manageGlobalSelection() {
      //const checkedCount = this.users.filter((e) => e.checked).length;

      if (this.selectedMessages > 0) {
        if (this.selectedMessages == this.pager.totalItems) {
          this.isSelectAll = true;
          this.selectedMessages = this.pager.totalItems;
          this.indeterminate = false;
        } else if (!this.isSelectAll) {
          this.indeterminate = true;
        }
      } else {
        this.indeterminate = false;
        this.isSelectAll = false;
      }
    },
    searchOpen(e) {
      this.searchVisible = true;
    },
    searchTeams(e) {
      this.reset();
      this.search = e;
      this.getQueryData(true);
      /* this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.currentPage,
          perpage: this.itemsPerPage,
          //status: this.statusFilter,
          search: this.search,
          //selectAllSort: this.selectAllFilter,
        }),
      }); */
    },
    onResetClicked() {
      this.reset(true);
    },
    reset(resetClicked = false) {
      this.statusFilter = "";
      this.selectAllFilter = "";
      this.search = "";
      this.selectedMessages = 0;
      this.filter.key = "";
      this.filter.value = "";


      this.isSelectAll = false;
      this.indeterminate = false;
      this.sort = "Mode,Modified";
      this.order = "DESC";
      //this.selectedContactIds = [];
      this.currentPage = 1;
      this.ourcontents = this.ourcontents.map((e) => {
        e.checked = false;
        e.checkdClass = "";
        return e;
      });
      if (resetClicked) {
        this.getQueryData(true);
      }

      //console.log("-", this.selectAllFilter);

      /* let query = Object.assign({}, this.$route.query);
      this.sort = "Mode,Modified";
      delete query?.order;
      delete query?.search;
      delete query?.filterkey;
      delete query?.filtervalue;
      delete query?.status;
      delete query?.selectAllSort;
      this.$router.replace({ query }); */

      /* this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.currentPage,
          perpage: this.itemsPerPage
        }),
      }); */
      //this.getQueryData();
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.getQueryData()
      }
    }
  }
};
</script>

<style lang="scss">
.servererror {
  width: 100%;
  background: red;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
}

.messages-page {
  padding-bottom: 60px;
}

.sortable {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.startDateHeader {
  width: 13%;
  padding-left: 10px !important;
}


.new-message-btn {
  padding: 0 !important;
  width: 151px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  background: #344d7a !important;
  color: white !important;
  border-radius: 50px;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-color: #344D7A !important;
  border: none !important;

  & .add-icon {
    margin-left: 11px;
    margin-right: 8px;
  }

  &:hover {

    text-decoration: none !important;
    background-color: rgba(#344D7A, 0.7) !important;
  }

  @media screen and (max-width: 568px) {
    padding: 6px 20px 6px 16px;
    font-size: 12px !important;

    .md-icon {
      font-size: 16px !important;
    }
  }
}

.new-message-btn .new-message-btn .md-icon {
  color: white !important;
}


.messages-page-header {
  position: relative;

  .customsearch {
    color: #555;
    width: 80%;
    max-width: 444px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin: 0;
    background: transparent;
    position: relative;
    border: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 9px;
    animation: fadeDown 0.1s 1;
    height: 44px;

    input[type="search"] {
      background: #fff;
      margin: 0;
      padding: 10px 8px 10px 50px;
      border: 1px solid #BDBDBD;
      box-shadow: none;
      border-radius: 8px;
      font-size: 16px;
      color: inherit;
      width: 100%;
      height: 44px;
      transition: all 0.2s;

      &::placeholder {
        color: #bbb;
      }

      &:focus {
        box-shadow: none;
        border-color: #cacaca;
        outline: none;
      }

    }

    button {
      overflow: hidden;
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 0;
      border: 1px solid transparent;
      border-radius: inherit;
      cursor: pointer;
      opacity: 1;
      position: absolute;
      left: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 10px;
      font-weight: 600;
      pointer-events: all;

      &:focus,
      &:hover {
        background-color: none;
        box-shadow: none;
        border-color: none;
        outline: none;
      }

      &.clear-btn {
        background: none;
        font-size: 26px;
        right: 9px;
        line-height: 1;
        left: initial;
      }
    }
  }

  .search-btn {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 24px 0 0;

    &:hover {
      background-color: #fff;
    }

    &.hide {
      visibility: hidden;
    }
  }
}

@keyframes fadeDown {
  0% {
    height: 0;
    overflow: hidden;
    opacity: 0;
  }

  100% {
    height: auto;
    opacity: 1;
  }
}



.messages-table {
  margin-top: 26px;
  margin-bottom: 30px;

  @media screen and (max-width: 568px) {
    margin-top: 20px;
  }

  & .search_container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 89px;
    z-index: 20;
  }




  .message-setting-preview {
    display: flex;
    justify-content: end;
    width: 100%;

  }



}

.message-delete-alert-con {
  .alert-dismissible {
    border-radius: 10px;
    padding: 10px 16px 15px;
    line-height: 24px;
    border: 0;
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;

    &.alert-success {
      background: #D6FCC4;
    }

    &.alert-delete {
      background: #F5E0AB;
      margin-top: 30px;
      margin-bottom: 26px;
    }

    .alert-headeing {
      line-height: 155%;
      font-size: 20px;
    }
  }

  .alert-dismissible .close {
    padding: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.messages-page {
  & .selected_row_box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 89px;
    z-index: 20;
  }
}

.bulk_select {
  display: flex;
  justify-content: space-between;
  padding-left: 19px;
  padding-right: 19px;
  // padding-top: 10px;
  align-items: center;
  // padding-bottom: 14px;
  background: #fff;
  border-radius: 8px;
  width: 444px;
  height: 44px;
  top: 12px;

  label {
    font-weight: 500;
  }
}

.link {
  color: #4783e6;
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    cursor: pointer;
  }

  ::v-deep {
    svg {
      margin-right: 10px;
    }
  }
}
</style>
